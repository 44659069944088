import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import { getUserDetail } from "services/userManagement";
import { useAuthContext } from "providers/authProviders";
import Spinner from "components/atoms/Spinner";

import BasicLayout from "../BasicLayout";
import { useQuery } from "@tanstack/react-query";
import routesName from "routes/route";

const AuthLayout = () => {
  const { isAuthenticated, signinRedirect } = useAuthContext();
  const { user } = useSelector(({ auth }) => auth);
  const location = useLocation();

  const { refetch: fetchUserDetail } = useQuery({
    queryKey: ["user", user?.UserId],
    queryFn: () => getUserDetail(user?.UserId),
    enabled: false,
  });

  const [isChecking, setChecking] = useState(true);
  const [isAuth, setAuthenticated] = useState(false);
  const [isAuthorize, setAuthorized] = useState(false);

  const checkAuthentication = async () => {
    const resIsAuthenticated = await isAuthenticated();
    setAuthenticated(resIsAuthenticated);

    if (!resIsAuthenticated) {
      throw new Error("user unauthenticate");
    }
  };

  const checkAuthorization = async () => {
    const { data } = await fetchUserDetail(user.UserId);
    const userDetail = data?.data;

    if (userDetail?.userAccess && Array.isArray(userDetail.userAccess)) {
      const moduleMatched = userDetail.userAccess.find(
        (x) => x.moduleName?.toLowerCase() === "safeguard"
      );

      // module not found
      if (!moduleMatched) {
        setAuthorized(false);
        return;
      }

      const userAccess = moduleMatched?.menuModules ?? [];
      const userAccessMapped = userAccess.reduce((obj, item) => {
        return Object.assign(obj, {
          [item.key]: { ...item },
        });
      }, {});

      // doesnt have access right
      if (!userAccessMapped) {
        setAuthorized(false);
        return;
      }

      const { routeKey, routeAction } = routesName.find((route) => {
        return route.path === location.pathname;
      });

      const accessAuthorized = userAccessMapped[routeKey]?.[routeAction];
      setAuthorized(accessAuthorized);
    }
  };

  useEffect(() => {
    (async () => {
      setChecking(true);

      try {
        await checkAuthentication();
        await checkAuthorization();
      } catch (error) {
        console.log("auth validation failed:", error);
      } finally {
        setChecking(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user]);

  useEffect(() => {
    if (!isChecking && !isAuth) {
      signinRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecking, isAuth]);

  if (!isAuth || isChecking) {
    if (isAuth) {
      return (
        <BasicLayout>
          <div className="relative min-h-screen">
            <Spinner />
          </div>
        </BasicLayout>
      );
    }

    return (
      <div className="relative min-h-screen">
        <Spinner />
      </div>
    );
  }

  if (!isChecking && !isAuthorize) {
    return (
      <BasicLayout>
        <p>Anda tidak memiliki akses, hubungi admin.</p>
      </BasicLayout>
    );
  }

  return (
    <BasicLayout>
      <Outlet />
    </BasicLayout>
  );
};

export default AuthLayout;
