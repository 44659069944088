import React from "react";

import { AuthConsumer } from "../../providers/authProviders";
import Spinner from "../atoms/Spinner";

const Callback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback();
      return (
        <div className="relative min-h-screen">
          <Spinner />
        </div>
      );
    }}
  </AuthConsumer>
);

export default Callback;
