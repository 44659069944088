const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL || "https://bpldh-api-v2.groot.id",
  INTERNAL_AUDIT_API_URL:
    process.env.REACT_APP_BASE_URL + "/api/internal-audit/v1",
  SAFEGUARD_API_URL: process.env.REACT_APP_BASE_URL + "/api/safeguard/v1/api",
  MASTER_API_URL: process.env.REACT_APP_BASE_URL + "/api/master",
  USER_MANAGEMENT_API_URL:
    process.env.REACT_APP_BASE_URL + "/api/user-management/v1",
};

export default config;
