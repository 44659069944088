import { UserManager } from "oidc-client";

// import { setRequestHeader } from "utils/auth";
import store from "app/store";

import { IDENTITY_CONFIG, METADATA_OIDC } from "../contants/auth";
import { StorageAuthStateStore } from "../app/storageAuthStateStore";
import { logoutUser, storeUser } from "../redux/authSlice";
import { addOIDCUser, removeOIDCUser } from "../redux/oidcSlice";

export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new StorageAuthStateStore(),
      // stateStore: new WebStorageStateStore(),
      metadata: {
        ...METADATA_OIDC,
      },
    });

    this.UserManager.events.addUserLoaded((user) => {
      store.dispatch(storeUser(user?.profile));
      store.dispatch(addOIDCUser(user));

      // TODO: Implement this if there is issue when request api with token
      // currently disable, cause in this module not implement axios instance
      // ref: module hukum-peraturan
      // setRequestHeader(user?.access_token);

      if (window.location.href.indexOf("signin-oidc") !== -1) {
        window.location.replace(localStorage.getItem("redirectUri"));
      }
    });

    this.UserManager.events.addUserUnloaded(() => {
      console.log("user unloaded");
      localStorage.clear();
      sessionStorage.clear();

      store.dispatch(logoutUser());
      store.dispatch(removeOIDCUser());
    });

    this.UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.logout();
    });
  }

  signinRedirectCallback = async () => {
    try {
      const user = await this.UserManager.signinRedirectCallback();

      store.dispatch(storeUser(user?.profile));
      store.dispatch(addOIDCUser(user));

      // TODO: Implement this if there is issue when request api with token
      // currently disable, cause in this module not implement axios instance
      // ref: module hukum-peraturan
      // setRequestHeader(user?.access_token);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  signinRedirect = () => {
    // store.dispatch(userSignedOut());
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  isAuthenticated = () => {
    const userOIDC = store.getState().oidc.oidc_user;
    const user = store.getState().auth.user;
    // const idsrv = cookies.get("idsrv");
    // const idsrvSession = cookies.get("idsrv.session");

    return !!user && !!userOIDC.access_token;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log("signed in", user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  logout = async () => {
    try {
      await this.UserManager.signoutRedirect();
      await this.UserManager.clearStaleState();
      store.dispatch(logoutUser());
    } catch (error) {
      console.log("error: ", error);
    }
  };

  signoutRedirectCallback = async () => {
    await this.UserManager.signoutRedirectCallback();

    localStorage.clear();
    sessionStorage.clear();

    store.dispatch(logoutUser());
    store.dispatch(removeOIDCUser());

    window.location.replace("/");
  };
}
