import config from "app/config";
import axiosInstance from "app/interceptors";

export const getUserDetail = async (userId, options = {}) => {
  const { data } = await axiosInstance.get(
    `${config.USER_MANAGEMENT_API_URL}/User/internal/${userId}`,
    options
  );
  return data;
};
