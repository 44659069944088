import { useLayoutEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import _ from "lodash";

import { AuthLayout, BasicLayout } from "components";
import {
  CALLBACK,
  LOGOUT,
  LOGOUT_CALLBACK,
  SILENT_RENEW,
} from "components/auth";
import NotFound from "pages/errors/404";

import route from "./route";

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const SafeguardRoutes = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Navigate to="/safeguard" replace />} />
        <Route path="/signin-oidc" element={<CALLBACK />} />
        <Route path="/signout-oidc" element={<LOGOUT_CALLBACK />} />
        <Route path="/silentrenew" component={<SILENT_RENEW />} />
        <Route path="/logout" element={<LOGOUT />} />

        <Route element={<BasicLayout />}>
          {_.map(_.filter(route, { routeType: "public" }), (val, key) => (
            <Route
              exact={true}
              key={key}
              path={val.path}
              element={<val.element />}
            />
          ))}
        </Route>

        <Route element={<AuthLayout />}>
          {_.map(_.filter(route, { routeType: "private" }), (val, key) => (
            <Route
              exact={true}
              key={key}
              path={val.path}
              element={<val.element />}
            />
          ))}
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);

export default SafeguardRoutes;
