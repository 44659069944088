import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import store from './store';

const token = localStorage.getItem("token");

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    'ngrok-skip-browser-warning': true,
    common: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  },
});

const generateErrorMessage = (error) => {
  let message = "Something went wrong";
  if (error?.response?.data?.message) {
    message = error?.response?.data?.message;
  } else if (error?.response?.data?.error?.message) {
    message = error?.response?.error?.message;
  } else if (error?.data?.message) {
    message = error?.data?.message;
  } else if (error?.data?.error?.message) {
    message = error?.data?.error?.message;
  }
  return message;
};

const AxiosInterceptorWrapper = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const requestInterceptor = (config) => {
      let userOidc = store.getState().oidc.oidc_user;
      if (userOidc?.access_token) {
        config.headers["Authorization"] = `Bearer ${userOidc.access_token}`;
      }
      if (config.url.includes("storage.groot.id")) {
        config.headers["Authorization"] = undefined;
      }
      return config;
    };

    const responseInterceptor = (response) => {
      if ("error" in response?.data && !isEmpty(response?.data?.error)) {
        enqueueSnackbar(generateErrorMessage(response), {
          autoHideDuration: 2500,
          variant: "error",
        });
        return Promise.reject(response);
      }
      return response;
    };

    const errorInterceptor = (error) => {
      if (
        error?.response?.data?.error?.name === "JWSInvalid" ||
        error?.response?.data?.error?.name === "JWTExpired" ||
        error?.response?.data?.message?.includes("claim timestamp")
      ) {
        window.location.replace(`${window.location.origin}/logout`);
      }

      if (error?.response?.status === 401) {
        window.location.replace(`${window.location.origin}/logout`);
      }

      if (axios.isCancel(error)) {
        return;
      }

      enqueueSnackbar(generateErrorMessage(error), {
        autoHideDuration: 2500,
        variant: "error",
      });

      return Promise.reject(error);
    };

    const resInterceptor = axiosInstance.interceptors.response.use(
      responseInterceptor,
      errorInterceptor
    );

    const reqInterceptor =
      axiosInstance.interceptors.request.use(requestInterceptor);

    return () => {
      axiosInstance.interceptors.response.eject(resInterceptor);
      axiosInstance.interceptors.request.eject(reqInterceptor);
    };
  }, []);

  return children;
};

export default axiosInstance;
export { AxiosInterceptorWrapper };
