import React from "react";
import { Link } from "react-router-dom";
import { RiHome6Line } from "react-icons/ri";
import { FiChevronRight } from "react-icons/fi";

const Breadcrumbs = ({ items }) => {
  return (
    <div className="bg-transparent flex items-center flex-wrap flex-1">
      <ul className="flex items-center">
        <li className="inline-flex items-center">
          <Link to="/">
            <RiHome6Line className="text-gray-500 hover:text-primary-700" />
          </Link>
          <FiChevronRight className="text-gray-300 mx-2 w-5" />
        </li>
        {Array.isArray(items) && items.length
          ? items.map((item, key) => (
              <li className="inline-flex items-center" key={key}>
                {item?.path ? (
                  <Link
                    to={item.path}
                    className="mb-0 text-gray-600 text-sm hover:text-primary-700"
                  >
                    {item.label}
                  </Link>
                ) : item?.isActive ? (
                  <p className="mb-0 text-primary-700 text-sm">{item.label}</p>
                ) : (
                  <p className="mb-0 text-gray-600 text-sm">{item.label}</p>
                )}
                {key < items.length - 1 && (
                  <FiChevronRight className="text-gray-300 mx-2 w-5" />
                )}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

export default React.memo(Breadcrumbs);
